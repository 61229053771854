import dynamic from "next/dynamic";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { Centered } from "../../components/Centered";
import Title from "../../components/Elements/Title";
import MetaTags from "../../components/MetaTags";
import LoadingPageIndicator, {
    ShouldShowPageIndicator,
} from "../../components/LoadingPageIndicator";
import { GetCampaignInfoSSR } from "../../lib/queries/pages/CampaignPageQuery";

const VerticalSplitContained = dynamic(
    () => import("../../components/VerticalSplitContained"),
    {
        ssr: false,
    }
);
const heading = {
    heading: "Våre kampanjer",
    headingMobile: "Våre kampanjer",
};

const DescriptionText = styled.p`
    max-width: 60%;
    text-align: center;
    color: ${(props) => props.theme.darker};
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0 auto;
`;
function isOdd(num) {
    return num % 2;
}

const CampaignPage = ({ info }) => {
    if (ShouldShowPageIndicator()) {
        return <LoadingPageIndicator />;
    }
    return (
        <>
            {info.meta && (
                <MetaTags
                    title={info.meta.metatitle}
                    description={info.meta.metadescription}
                    url={info.meta.metaurl}
                    image={info.meta.metaimage}
                    keywords={info.meta.metakeywords}
                />
            )}
            <Centered>
                <Title
                    heading={heading.heading}
                    headingMobile={heading.headingMobile}
                />
            </Centered>
            {info.description && (
                <DescriptionText>
                    {ReactHtmlParser(info.description)}
                </DescriptionText>
            )}
            {info.featured && (
                <VerticalSplitContained
                    data={{
                        heading: info.featured?.title,
                        text: info.featured?.description,
                        image: info.featured?.image,
                        cta: info.featured?.callToAction,
                    }}
                />
            )}
            {info &&
                info.campaigns.map((item, idx) => {
                    if (isOdd(idx)) {
                        return (
                            <VerticalSplitContained
                                key={item.heading + idx}
                                data={item}
                                isFlipped={true}
                            />
                        );
                    }
                    return (
                        <VerticalSplitContained
                            key={item.heading + idx}
                            data={item}
                        />
                    );
                })}
        </>
    );
};

export async function getStaticProps() {
    const info = await GetCampaignInfoSSR();
    const headingData = {
        heading: info.title,
        subheading: "VÅRE",
        mainImage: "",
        mobileImage: "",
        href: "#",
    };

    return {
        props: { info, headingData },
        revalidate: 300,
    };
}

export default CampaignPage;
